import { useEffect } from 'react';
import { axiosPrivate } from '../config/axios';
import { useNavigate, useLocation } from 'react-router-dom';
import { removeUserLoggedInDetails, storeUserLoggedInDetails } from '../services/utils/Helper';

const useAxios = () => {
    const user = localStorage.getItem('user')
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const requestIntercept = axiosPrivate.interceptors.request.use(async config => {
            const token = localStorage.getItem('token');
            if (!config.headers['Authorization'] && token) {
                config.headers['Authorization'] = `Bearer ${token}`;
            }
            return config;
        }, (error) => Promise.reject(error));

        let refreshingFunc  = undefined;

        const responseIntercept = axiosPrivate.interceptors.response.use(response => {
            return response;
        },
            async (error) => {
                const previousRequest = error?.config;
                if (error?.response?.status === 401 && !previousRequest._retry) {
                    previousRequest._retry = true;

                    try {
                        const token = localStorage.getItem('refreshToken');

                        if (!refreshingFunc)
                            refreshingFunc = refreshToken(token);

                        const [id_token, refresh_token] = await refreshingFunc;


                        if (id_token && refresh_token) {
                            storeUserLoggedInDetails(user, refresh_token, id_token, Date.now());

                            previousRequest.headers['Authorization'] = `Bearer ${id_token}`;

                            return axiosPrivate(previousRequest);
                        } else {
                            logout();
                            return Promise.reject(error);
                        }
                    } catch (error) {
                        logout();
                        return Promise.reject(error);
                    } finally {
                        refreshingFunc = undefined;
                    }
                }

                return Promise.reject(error);
            })

        return () => {
            axiosPrivate.interceptors.request.eject(requestIntercept);
            axiosPrivate.interceptors.response.eject(responseIntercept);
        }
    });

    const refreshToken = async (token) => {
        const config = {
            headers: {
                "Authorization": `${token}`
            }
        }

         const response = await axiosPrivate.get('/auth/token/refresh', config);
         const id_token = response.data.id_token;
         const refresh_token = response.data.refresh_token;

         return [id_token, refresh_token];
    }

    const logout = () => {
        removeUserLoggedInDetails();
        navigate("/login", { state: { from: location } });
    }

    return axiosPrivate;
}

export default useAxios;